import React, { Fragment } from "react";
import PagesTraffic from "../components/analytics/PagesTraffic";

const Pages = () => (
  <Fragment>
    <PagesTraffic />
    <hr />
  </Fragment>
);

export default Pages;
