import React from 'react';
import { gapi } from 'gapi-script';
// import { loadAuth2, loadAuth2WithProps } from 'gapi-script';
import { Line, Pie } from 'react-chartjs-2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loading from "../../assets/loading.svg";
import Select from 'react-select';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import svgexport from '../../assets/img/export.svg';

// STYLES
import "../../assets/css/metrics.css";

const options = [
  { value: 'gaid::v8z62PyhTau4H0RflL0-8w', label: 'FR PRO' },
  { value: 'gaid::eqc-K_L6TP-KSYt8HSEJPw', label: 'NL PRO' },
];

class AllTraffic extends React.Component{  
    constructor(props){
        
        super(props);
        this.onSignIn = this.onSignIn.bind(this)
        this.changeParams = this.changeParams.bind(this);
        this.state = {
          users: {},
          minEndDate: '',
          maxStartDate: this.formatDate(new Date(new Date().setDate(new Date().getDate()-1))),
          maxEndDate: this.formatDate(new Date()),
          startDate: this.formatDate(new Date(new Date().setDate(new Date().getDate()-30))),
          endDate: this.formatDate(new Date(new Date().setDate(new Date().getDate()-1))),
          expression: 0,
          dimension: 'ga:date',
          linePoints: 3,
          lineLabel: 'Utilisateurs',
          isLoaded: 'isLoaded',
          selectedOption: { value: 'gaid::v8z62PyhTau4H0RflL0-8w', label: 'FR PRO' },
          pieChart: [50,50],
          error: ''
        }
    }

    componentDidMount() {
        gapi.signin2.render('g-signin2', {
            'scope': 'profile email',
            'width': 300,
            'height': 50,
            'longtitle': true,
            'theme': 'dark',
            'onsuccess': this.onSignIn,
            'onfailure': (error) => {
              console.log(error);
            }
        });
    }

    printDocument() {
      const input = document.getElementById('exportDatas');
      html2canvas(input, { allowTaint: true, backgroundColor: "rgba(7, 29, 50, 1)", scrollX: 0, scrollY: 0 } )
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: "landscape"
          });
          pdf.addImage(imgData, 'PNG', 0, 0);
          pdf.save("export.pdf");
        })
      ;
    }

    onSignIn(googleUser) {
      this.setState({isLoaded: ''});
      this.getData(this.state.startDate, this.state.endDate, this.state.expression, this.state.dimension, this.state.selectedOption);
    }

    async changeParams(dimension, startDate, endDate, expression){
      this.setState({
        isLoaded: '',
        error: ''
      });
      if(dimension !== null){
        await this.setState({dimension: dimension});
      }else if(startDate !== null){
        await this.setState({
          startDate: startDate,
          minEndDate: startDate
        });
      }else if(endDate !== null){
        await this.setState({
          endDate: endDate,
          maxStartDate: endDate
        });
      }else if(expression !== null){
        await this.setState({
          expression: expression,
        });
      }
      await this.getData(this.state.startDate, this.state.endDate, this.state.expression, this.state.dimension, this.state.selectedOption);
    }

    getData(startDate, endDate, expression, dimension, segment){
      console.log(segment);
      gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '124728406',
              dateRanges: [{
                startDate: startDate, 
                endDate: endDate
              }],
              metrics: [
                {expression: 'ga:users'},
                {expression: 'ga:newUsers'},
                {expression: 'ga:sessions'},
                {expression: 'ga:sessionsPerUser'},
                {expression: 'ga:pageviews'},
                {expression: 'ga:pageviewsPerSession'},
                {expression: 'ga:avgSessionDuration'},
                {expression: 'ga:bounceRate'},
                {expression: 'ga:visits'},
                {expression: 'ga:newVisits'}
              ],
              dimensions: [
                {name: 'ga:segment'},
                {name: dimension}
              ],
              segments:  [
                {segmentId: segment.value},
              ]
            }
          ]
        }
      }).then((response) => {
        let rows = response.result.reports[0].data.rows || []

        // FORMATAGE LABELS
        if(dimension === 'ga:date' || dimension === 'ga:dateHour'){
          var labels = rows.map(
            item => this.formatDateForLine(item.dimensions[1], dimension)
          );
        }else{
          var labels = rows.map(
            item => item.dimensions[1]
          );
        }
        // FORMATAGE DATA
        const values = rows.map(
          item => Math.round((Number(item.metrics[0].values[expression]) + Number.EPSILON) * 100) / 100
        );

        // ADD OR REMOVE POINT
        if(values.length > 100){

          this.setState({linePoints: 0});
        }else{
          this.setState({linePoints: 3});
        }

        // CHANGE LABEL
        switch(expression){
          case 0:
            this.setState({lineLabel: 'Utilisateurs'});
            break;
          case 1:
            this.setState({lineLabel: 'Nouveaux utilisateurs'});
            break;
          case 2:
            this.setState({lineLabel: 'Sessions'});
            break;
          case 3:
            this.setState({lineLabel: 'Nombre de sessions par utilisateur'});
            break;
          case 4:
            this.setState({lineLabel: 'Pages vues'});
            break;
          case 5:
            this.setState({lineLabel: 'Pages/session'});
            break;
          case 6:
            this.setState({lineLabel: 'Durée moyenne des sessions'});
            break;
          case 7:
            this.setState({lineLabel: 'Taux de rebond'});
            break;
          default:
            this.setState({lineLabel: 'Utilisateurs'});
        }

        // LOAD DATA
        this.setState({
          isLoaded: 'isLoaded',
          users: {
            labels: labels,
            values: values,
            total: response.result.reports[0].data.totals[0].values[0],
            newUsers: response.result.reports[0].data.totals[0].values[1],
            sessions: response.result.reports[0].data.totals[0].values[2],
            usersPerSessions: Math.round((Number(response.result.reports[0].data.totals[0].values[3]) + Number.EPSILON) * 100) / 100,
            pagesView: response.result.reports[0].data.totals[0].values[4],
            pagesPerSessions: Math.round((Number(response.result.reports[0].data.totals[0].values[5]) + Number.EPSILON) * 100) / 100,
            sessionsDuration: new Date(Number(response.result.reports[0].data.totals[0].values[6]) * 1000).toISOString().substr(11, 8),
            bounceRate: Math.round((Number(response.result.reports[0].data.totals[0].values[7]) + Number.EPSILON) * 100) / 100
          }
        });

        // CALCULATE PIE CHART
        let percentageNew = Math.round((this.state.users.newUsers / this.state.users.total)*100);
        let percentageOld = 100 - percentageNew;

        this.setState({
          pieChart: [percentageNew,percentageOld]
        });

      }).catch((error) => {
        var body = JSON.parse(error.body);
        this.setState({
          isLoaded: 'isLoaded',
          error: body.error.message
        });
      });
    }

    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }

    formatDateForLine(date, dimension){
      let months = {
        '01': 'janvier',
        '02': 'février',
        '03': 'mars',
        '04': 'avril',
        '05': 'mai',
        '06': 'juin',
        '07': 'juillet',
        '08': 'août',
        '09': 'septembre',
        '10': 'octobre',
        '11': 'novembre',
        '12': 'décembre',
      };

      var m = date.substring(4, 6);
      var d = date.substring(6, 8);
      var h = date.substring(8, 10) + 'h';

      if(dimension == 'ga:date'){
        return d + ' ' + months[m];
      }else{
        return d + ' ' + months[m] + ' ' + h;
      }
    }

    async selectChange(param){
      this.setState({
        isLoaded: '',
        error: ''
      });

      await this.setState({ selectedOption: param });
      await this.getData(this.state.startDate, this.state.endDate, this.state.expression, this.state.dimension, this.state.selectedOption);
    };

    render() {
        return(
            <div className="metrics container">
                <div className={this.state.isLoaded} id="data-Loader">
                  <img src={loading} alt="Loading" />
                </div>
               
                <p id="g-signin2" data-onsuccess="queryReports"></p>

                <button onClick={this.printDocument} className="export-btn"><img src={svgexport} /> Exporter</button>

                <div id="exportDatas">
                  <ul className="toolsMenu">
                    <li className="pathTool">
                      <Select
                        value={this.state.selectedOption}
                        onChange={param => this.selectChange(param)}
                        options={options}
                      />
                    </li>
                    <li><input type="date" value={this.state.startDate} max={this.state.maxStartDate} onChange={e => this.changeParams(null, e.target.value, null, null)} required /></li>
                    <li><input type="date" value={this.state.endDate} min={this.state.minEndDate} max={this.state.maxEndDate} onChange={e => this.changeParams(null, null, e.target.value, null)} required /></li>
                    <li><a onClick={() => this.changeParams('ga:dateHour', null, null, null)}>Toutes les heures</a></li>
                    <li><a onClick={() => this.changeParams('ga:date', null, null, null)}>Jours</a></li>
                    <li><a onClick={() => this.changeParams('ga:week', null, null, null)}>Semaines</a></li>
                    <li><a onClick={() => this.changeParams('ga:month', null, null, null)}>Mois</a></li>
                  </ul>

                  <p className="error">{this.state.error}</p>

                  <div style={{height: '250px'}}>
                    <Line
                      options={{ 
                        maintainAspectRatio: false,
                        scales: {
                          yAxes: [{
                            display: true,
                            ticks: {
                              fontColor: "white",
                            }
                          }],
                          xAxes: [{
                            ticks: {
                              fontColor: "white",
                            }
                          }]
                        },
                        legend: {
                          labels: {
                              fontColor: "white",
                              fontSize: 16
                          }
                        }
                      }}
                      data={{
                        labels: this.state.users.labels,
                        datasets: [{
                          label: this.state.lineLabel,
                          data: this.state.users.values,
                          backgroundColor: 'rgba(5, 141, 199, 0.2)',
                          pointBackgroundColor: 'rgba(5, 141, 199, 1)',
                          borderColor: 'rgba(5, 141, 199, 1)',
                          showLine: true,
                          lineTension: 0,
                          pointRadius: this.state.linePoints
                          // showLine: false
                        }]
                      }} />
                  </div>

                  <hr />

                  <div className="row sparkline">
                    <ul className="col-9 row">
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 0)}>Utilisateur</a></h3><strong>{this.state.users.total}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 1)}>Nouveaux utilisateurs</a></h3><strong>{this.state.users.newUsers}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 2)}>Sessions</a></h3><strong>{this.state.users.sessions}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 3)}>Nombre de sessions par utilisateur</a></h3><strong>{this.state.users.usersPerSessions}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 4)}>Pages vues</a></h3><strong>{this.state.users.pagesView}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 5)}>Pages/session</a></h3><strong>{this.state.users.pagesPerSessions}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 6)}>Durée moyenne des sessions</a></h3><strong>{this.state.users.sessionsDuration}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 7)}>Taux de rebond</a></h3><strong>{this.state.users.bounceRate} %</strong>
                      </li>
                    </ul>
                    <div className="col-3 text-center">
                    <Pie
                      height={400}
                      width={400}
                      data={{
                        datasets: [{
                          data: this.state.pieChart,
                          backgroundColor: ['#50B432','#058DC7'],
                        }],
                        labels: ['New visitors', 'Returning visitors'],
                      }} />
                    </div>
                  </div>
                </div>
            </div>
        )
    }
}

export default AllTraffic