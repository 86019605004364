import React from 'react';
import { gapi } from 'gapi-script';
// import { loadAuth2, loadAuth2WithProps } from 'gapi-script';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import svgexport from '../../assets/img/export.svg';

import loading from "../../assets/loading.svg";

// STYLES
import "../../assets/css/metrics.css";

const options = [
    { value: '/fr/vehicules-utilitaires/fourgons/citroen-berlingo-van.html', label: 'BERLINGO VAN FR' },
    { value: '/nl/bedrijfswagens/bestelwagens/citroen-berlingo-van.html', label: 'BERLINGO VAN NL' },
    { value: '/fr/vehicules-utilitaires/fourgons/citroen-jumpy.html', label: 'JUMPY FR' },
    { value: '/nl/bedrijfswagens/bestelwagens/citroen-jumpy.html', label: 'JUMPY NL' },
    { value: '/fr/vehicules-utilitaires/fourgons/citroen-jumper.html', label: 'JUMPER FR' },
    { value: '/nl/bedrijfswagens/bestelwagens/citroen-jumper.html', label: 'JUMPER NL' },
];

class PagesTraffic extends React.Component{  
    constructor(props){
        

        super(props);
        this.onSignIn = this.onSignIn.bind(this)
        this.changeParams = this.changeParams.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.state = {
          pages: {},
          minEndDate: '',
          maxStartDate: this.formatDate(new Date(new Date().setDate(new Date().getDate()-1))),
          maxEndDate: this.formatDate(new Date()),
          startDate: this.formatDate(new Date(new Date().setDate(new Date().getDate()-60))),
          endDate: this.formatDate(new Date(new Date().setDate(new Date().getDate()-30))),
          expression: 0,
          dimension: 'ga:date',
          linePoints: 3,
          lineLabel: 'Pages vues',
          isLoaded: 'isLoaded',
          selectedOption: { value: '/fr/vehicules-utilitaires/fourgons/citroen-berlingo-van.html', label: 'BERLINGO VAN FR' },
          error: ''
        }
    }

    componentDidMount() {
        gapi.signin2.render('g-signin2', {
            'scope': 'profile email',
            'width': 300,
            'height': 50,
            'longtitle': true,
            'theme': 'dark',
            'onsuccess': this.onSignIn,
            'onfailure': (error) => {
              console.log(error);
            }
        });
    }

    printDocument() {
      const input = document.getElementById('exportDatas');
      html2canvas(input, { allowTaint: true, backgroundColor: "rgba(7, 29, 50, 1)", scrollX: 0, scrollY: 0 } )
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: "landscape"
          });
          pdf.addImage(imgData, 'PNG', 0, 0);
          pdf.save("export.pdf");
        })
      ;
    }

    onSignIn(googleUser) {
      this.setState({isLoaded: ''});
      this.getData(this.state.startDate, this.state.endDate, this.state.expression, this.state.dimension, [this.state.selectedOption.value]);
    }

    async changeParams(dimension, startDate, endDate, expression, path){
      this.setState({
        isLoaded: '',
        error: ''
      });
      if(dimension !== null){
        await this.setState({dimension: dimension});
      }else if(startDate !== null){
        await this.setState({
          startDate: startDate,
          minEndDate: startDate
        });
      }else if(endDate !== null){
        await this.setState({
          endDate: endDate,
          maxStartDate: endDate
        });
      }else if(expression !== null){
        await this.setState({
          expression: expression,
        });
      }
      await this.getData(this.state.startDate, this.state.endDate, this.state.expression, this.state.dimension, [this.state.selectedOption.value]);
    }

    async selectChange(param){
      this.setState({
        isLoaded: '',
        error: ''
      });
      await this.setState({ selectedOption: param });
      await this.getData(this.state.startDate, this.state.endDate, this.state.expression, this.state.dimension, [this.state.selectedOption.value]);
    };

    getData(startDate, endDate, expression, dimension, path){
      gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '124728406',
              dateRanges: [{
                startDate: startDate, 
                endDate: endDate
              }],
              metrics: [
                {expression: 'ga:pageviews'},
                {expression: 'ga:uniquePageviews'},
                {expression: 'ga:avgTimeOnPage'},
                {expression: 'ga:entrances'},
                {expression: 'ga:bounceRate'},
                {expression: 'ga:exitRate'},
                {expression: 'ga:pageValue'}
              ],
              dimensions: [
                {name: dimension},
                {name: 'ga:pagePath'}
              ],
              dimensionFilterClauses: [{
                filters: [{
                   operator: 'EXACT',
                   dimensionName: 'ga:pagePath',
                   expressions: [path]
                }]
              }]
            }
          ]
        }
      }).then((response) => {  
        let rows = response.result.reports[0].data.rows || [];
        
        // FORMATAGE LABELS
        if(dimension === 'ga:date'){
          var labels = rows.map(
            item => this.formatDateForLine(item.dimensions[0], dimension)
          );
        }else{
          var labels = rows.map(
            item => item.dimensions[0]
          );
        }
        // FORMATAGE DATA
        const values = rows.map(
          item => Math.round((Number(item.metrics[0].values[expression]) + Number.EPSILON) * 100) / 100
        );

        // ADD OR REMOVE POINT
        if(values.length > 100){
          this.setState({linePoints: 0});
        }else{
          this.setState({linePoints: 3});
        }

        // CHANGE LABEL
        switch(expression){
          case 0:
            this.setState({lineLabel: 'Pages vues'});
            break;
          case 1:
            this.setState({lineLabel: 'Vues uniques'});
            break;
          case 2:
            this.setState({lineLabel: 'Temps moyen passé sur la page'});
            break;
          case 3:
            this.setState({lineLabel: 'Entrées'});
            break;
          case 4:
            this.setState({lineLabel: 'Taux de rebond'});
            break;
          case 4:
            this.setState({lineLabel: 'Sorties (en %)'});
            break;
          case 5:
            this.setState({lineLabel: 'Valeur de la page'});
            break;
          default:
            this.setState({lineLabel: 'Pages vues'});
        }

        // LOAD DATA
        this.setState({
          isLoaded: 'isLoaded',
          pages: {
            labels: labels,
            values: values,
            pageView: response.result.reports[0].data.totals[0].values[0],
            uniquePageviews: response.result.reports[0].data.totals[0].values[1],
            avgTimeOnPage: new Date(Number(response.result.reports[0].data.totals[0].values[2]) * 1000).toISOString().substr(11, 8),
            entrances: Math.round((Number(response.result.reports[0].data.totals[0].values[3]) + Number.EPSILON) * 100) / 100,
            bounceRate: Math.round((Number(response.result.reports[0].data.totals[0].values[4]) + Number.EPSILON) * 100) / 100,
            exitRate: Math.round((Number(response.result.reports[0].data.totals[0].values[5]) + Number.EPSILON) * 100) / 100,
            pageValue: Math.round((Number(response.result.reports[0].data.totals[0].values[6]) + Number.EPSILON) * 100) / 100
          }
        });
      }).catch((error) => {
        var body = JSON.parse(error.body);
        this.setState({
          isLoaded: 'isLoaded',
          error: body.error.message
        });
      });
    }

    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }

    formatDateForLine(date, dimension){
      let months = {
        '01': 'janvier',
        '02': 'février',
        '03': 'mars',
        '04': 'avril',
        '05': 'mai',
        '06': 'juin',
        '07': 'juillet',
        '08': 'août',
        '09': 'septembre',
        '10': 'octobre',
        '11': 'novembre',
        '12': 'décembre',
      };

      var m = date.substring(4, 6);
      var d = date.substring(6, 8);
      var h = date.substring(8, 10) + 'h';

      if(dimension == 'ga:date' ){
        return d + ' ' + months[m];
      }else{
        return d + ' ' + months[m] + ' ' + h;
      }
    }

    render() {
        return(
            <div className="metrics container">
                <div className={this.state.isLoaded} id="data-Loader">
                  <img src={loading} alt="Loading" />
                </div>
               
                <p id="g-signin2" data-onsuccess="queryReports"></p>

                <button onClick={this.printDocument} className="export-btn"><img src={svgexport} /> Exporter</button>

                <div id="exportDatas">
                <ul className="toolsMenu">
                  <li className="pathTool">
                    <Select
                      value={this.state.selectedOption}
                      onChange={param => this.selectChange(param)}
                      options={options}
                    />
                  </li>
                  <li><input type="date" value={this.state.startDate} max={this.state.maxStartDate} onChange={e => this.changeParams(null, e.target.value, null, null)} required /></li>
                  <li><input type="date" value={this.state.endDate} min={this.state.minEndDate} max={this.state.maxEndDate} onChange={e => this.changeParams(null, null, e.target.value, null)} required /></li>
                  <li><a onClick={() => this.changeParams('ga:date', null, null, null)}>Jours</a></li>
                  <li><a onClick={() => this.changeParams('ga:week', null, null, null)}>Semaines</a></li>
                  <li><a onClick={() => this.changeParams('ga:month', null, null, null)}>Mois</a></li>
                </ul>

                <p className="error">{this.state.error}</p>

                <div style={{height: '250px'}}>
                  <Line
                    options={{ 
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [{
                          display: true,
                          ticks: {
                            fontColor: "white",
                          }
                        }],
                        xAxes: [{
                          ticks: {
                            fontColor: "white",
                          }
                        }]
                      },
                      legend: {
                        labels: {
                            fontColor: "white",
                            fontSize: 16
                        }
                      }
                    }}
                    data={{
                      labels: this.state.pages.labels,
                      datasets: [{
                        label: this.state.lineLabel,
                        data: this.state.pages.values,
                        backgroundColor: 'rgba(5, 141, 199, 0.2)',
                        pointBackgroundColor: 'rgba(5, 141, 199, 1)',
                        borderColor: 'rgba(5, 141, 199, 1)',
                        showLine: true,
                        lineTension: 0,
                        pointRadius: this.state.linePoints
                      }]
                    }} />
                  </div>

                  <hr />

                  <div className="row sparkline">
                    <ul className="col-12 row">
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 0)}>Pages vues</a></h3><strong>{this.state.pages.pageView}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 1)}>Vues uniques</a></h3><strong>{this.state.pages.uniquePageviews}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 2)}>Temps moyen passé sur la page</a></h3><strong>{this.state.pages.avgTimeOnPage}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 3)}>Entrées</a></h3><strong>{this.state.pages.entrances}</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 4)}>Taux de rebond</a></h3><strong>{this.state.pages.bounceRate} %</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 5)}>Sorties (en %)</a></h3><strong>{this.state.pages.exitRate} %</strong>
                      </li>
                      <li className="col-3">
                        <h3><FontAwesomeIcon icon="link" className="mr-2" /><a onClick={() => this.changeParams(null, null, null, 6)}>Valeur de la page</a></h3><strong>{this.state.pages.pageValue} €</strong>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
        )
    }
}

export default PagesTraffic