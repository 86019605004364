import React, { Fragment } from "react";
import AllTrafficPro from "../components/analytics/AllTrafficPro";

const Home = () => (
  <Fragment>
    <AllTrafficPro />
    <hr />
  </Fragment>
);

export default Home;
