import React, { Fragment } from "react";
import PagesTrafficLu from "../components/analytics/PagesTrafficLu";

const Pages = () => (
  <Fragment>
    <PagesTrafficLu />
    <hr />
  </Fragment>
);

export default Pages;
