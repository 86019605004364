import React, { Fragment } from "react";
import PagesTrafficPro from "../components/analytics/PagesTrafficPro";

const Pages = () => (
  <Fragment>
    <PagesTrafficPro />
    <hr />
  </Fragment>
);

export default Pages;
