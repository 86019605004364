import React from "react";
import logo from "../assets/img/logo_citroen.png";

const Footer = () => (
  <footer className="bg-light text-center">
    <p>
      <img src={logo} className="logo-citroen" />
    </p>
  </footer>
);

export default Footer;
