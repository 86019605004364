import React, { Fragment } from "react";
import AllTrafficLu from "../components/analytics/AllTrafficLu";

const Home = () => (
  <Fragment>
    <AllTrafficLu />
    <hr />
  </Fragment>
);

export default Home;
