import React, { Fragment } from "react";
import AllTraffic from "../components/analytics/AllTraffic";

const Home = () => (
  <Fragment>
    <AllTraffic />
    <hr />
  </Fragment>
);

export default Home;
